<template>
  <div>
    <NavBar></NavBar>
      <b-container>
        <h1>Changelog</h1>
        <hr>
        <p>Thanks for your feedback if you have posted one !</p>
        <h4>v1.3.0: 19th July 2022</h4>
        <p>Book Improvements</p>
        <ul>
          <li><strong>Generics</strong>: New chapter !</li>
          <li><strong>Package Imports</strong>: typo</li>
          <li><strong>Package Initialization</strong>: typo - blank import</li>
          <li><strong>Go Module</strong>: grammar error - it's => its</li>
          <li><strong>Errors</strong>: Typo fix</li>
          <li><strong>JSON / XML</strong>: Fix number of chars necessary to encode JSON / XML</li>
          <li><strong>Database</strong>: Add some footnotes to make test clearer</li>
          <li><strong>Go Language</strong>: Fix typo Designing, Building + grammar error on verb form "Go shows"</li>
          <li><strong>Setup Dev Env</strong>: Fix Typo on CPU (pprocessing => processing)</li>
          <li><strong>First Go Application</strong>: Add paragraph about imported package usage</li>

        </ul>
        <p>Website Improvements</p>
        <ul>
          <li>Add the pages for the Go tutorial</li>
          <li>Login/signup mechanism with Firebase</li>
          <li>Cart mechanism</li>
        </ul>
        <h4>v1.3.0: 5th April 2021</h4>
        <ul>
          <li><strong>Context</strong>: replace person1 and person2 by Alice and Bob</li>
          <li><strong>The Go language</strong>: fix typo in the word "semantics" (last section)</li>
          <li><strong>The Go language</strong>: fix Go release date in answers section => 2012</li>
          <li><strong>Interfaces</strong>: fix typo in code block string. "has received affection TO human"</li>
          <li><strong>Hexadecimal, octal, ASCII, UTF8, Unicode, Runes</strong>: section “How UTF8 work”, "The two additional bytes are beginning with 01" replaced by "The two additional bytes are beginning with 10" </li>
          <li><strong>First Go application</strong>: in the warning about imported package the sentence has been rephrased. "WARNING! be sure that you only use packages that you actually import." </li>
        </ul>
        <h4>v1.2.0: 4th April 2021</h4>
        <ul>
          <li><strong>Slices</strong>: add section about the common error related to passing a slice as input parameter to a function?</li>
          <li><strong>Hexadecimal, octal, ASCII, UTF8, Unicode, Runes</strong>: section “base 16”, 9f4 is the HEXAdecimal version of 2548</li>
          <li><strong>Programming a computer</strong>; fix typo in section “1.8.4 High-level languages”, Forgo =&gt; For Go.</li>
          <li><strong>Setup your dev env</strong> : env variables were not printed</li>
          <li><strong>Cheatsheet</strong> : fix type section “slices”. “A good resource for playing with maps” =&gt; “A good resource for playing with slices”</li>
          <li><strong>The terminal</strong> : command to display current dir is pwd not PWD (upper case error)</li>
          <li><strong>Variables const</strong> : fix code block in solution explained “var occupancy int occupancy = 12” was on the same line. Added a line break.</li>
          <li><strong>Is Go an OO language</strong>: fixed release date of SMALLTALK (SMALLTAlK-80 release in 1980 and not in the nineties.)</li>
          <li><strong>Go Modules </strong>: fix typo (missing space) in figure description “A module is a set of packages with a go.mod file and a go.sum file”</li>
        </ul>
      </b-container>
    <Footer></Footer>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  name: "Changelog",
  components: {Footer, NavBar},
  created() {
    window.scrollTo(0,0);
  },
}
</script>

<style scoped>

</style>